.curved-background {
    /* background-color: #ebf8ff; Adjust the background color if needed */
    clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%);
    position: relative;
    position: relative;
    overflow: hidden;
}

/* @media (max-width: 320px) {
    .Hero {
        position: relative;
        width: auto;
        height: 100vh;
        justify-content: center;
        align-items: center;
        
        background-image: url('../../../assets/ai-generated-labrador-retriever-dog.jpg');
        background-size: cover;
        background-position: center;
    }

    .Hero img {
        display: none; 
    }
} */

/* @media (max-width: 1440px){
    .homeContend{
        position: relative;
        justify-content: center;
        align-items: center;
        /* margin-left: 60px; */
    /* }
} */ 
@media (max-width: 1024px){
    .heroSection{
        height: 80vh;
    }

}

@media (max-width: 767px) {
    .heroSection {
      position: relative;
      background-image: url('https://storage.googleapis.com/pet-medi-staging/web-assets/dog-hero.png');
      background-size: cover;
      background-position: center;
    }
    .heroSection::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5); 
      z-index: 1;
    }
    .heroSection img {
      display: none;
    }
    .heroSection > * {
      position: relative;
      z-index: 2;
    }
  }
  
  @media (max-width: 376px){
    .topic{
        font-size: 25px;
        color: white;
        line-height: 1.5;
    }

    .topic1 {
      font-size: 50px;
    }

    .content{
      margin-top: 110px;
    }

    .excellence{
      font-size: 35px;
    }
}
