@media (max-width: 1023px) {
    .contactSection {
      position: relative;
      background-image: url('https://storage.googleapis.com/pet-medi-staging/web-assets/about3.png');
      background-size: cover;
      background-position: center;
    }
    .contactSection::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(7, 7, 7, 0.5); 
      z-index: 1;
    }
    .contactSection img {
      display: none;
    }
    .contactSection > * {
      position: relative;
      z-index: 2;
    }
  }