.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{
  font-family: poppins;
}

/* Scrollbar background */
::-webkit-scrollbar {
  width: 5px; /* Adjust the width of the scrollbar */
  
}

/* Scrollbar track */
::-webkit-scrollbar-track {
  background: #5E6C84;
  


}

/* Scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #0B66E4;
  border-radius: 6px; /* Adjust the border radius to reduce the size */
  border: 2px solid #0B66E4; /* Add a border to create space around the thumb */
}

/* Scrollbar corner (for both vertical and horizontal scrollbar) */
::-webkit-scrollbar-corner {
  background: #0052D5;
}
