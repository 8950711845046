/* Custom scrollbar styling */
.custom-scrollbar::-webkit-scrollbar {
    height: 3px; /* Adjust the height of the scrollbar */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #0052D5; /* Change the color of the scrollbar thumb */
    border-radius: 10px; /* Round the corners of the scrollbar thumb */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Change the color on hover */
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #555; /* Change the color of the scrollbar track */
  }

@media (max-width:376px){
  #guide{
    margin-top: 80px;
  }
}

@media (min-width:769px) and (max-width:1000px){
  .guideView{
    height: 32vh;
  }
}