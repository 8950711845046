@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.navbar-scrolled {
    background-color: white;
    color: black;
  }

.font-poppins {
  font-family: 'Poppins', sans-serif;
}

.glass-navbar {
  backdrop-filter: blur(10px); 
  background-color: rgba(255, 255, 255, 0); 
  /* border: 1px solid rgba(255, 255, 255, 0.18);  */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
}

.dropdown-menu.white-background {
  background-color: white;
  color: black;
}

.dropdown-item {
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.dropdown-menu.default-background {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.29);
}